<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table px-0">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            ノート管理
          </div>
        </div>
        <div class="row-input admin_center">
          <input
            class="form-control w-100"
            @keydown.enter.prevent="search()"
            v-model="key_word"
            placeholder="検索"
          />
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listNoteAllUser.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-4"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + listNoteAllUser.from }}</td>
        </template>
        <template v-slot:name="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.name }}
            </div>
          </td>
        </template>
        <template v-slot:email="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.email }}
            </div>
          </td>
        </template>
        <template v-slot:title="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:page_title="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.page_title }}
            </div>
          </td>
        </template>
        <template v-slot:title_note="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title_note }}
            </div>
          </td>
        </template>
        <!-- <template v-slot:content_note="{ item }">
          <td style="min-width: 120px; width: 15%" class="py-2">
            <div class="text-truncate-mess">
              <div v-html="item.content_note"></div>
            </div>
          </td>
        </template> -->
        <template v-slot:tags="{ item }">
          <td style="" class="py-2">
            <div v-if="item.tags">
              <p
                v-for="(value, index) in item.tags"
                :key="index"
                class="text-truncate-mess"
              >
                {{ value.tag_name }}
              </p>
            </div>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div class="w-5">
              <CButton
                class="mx-1 btn-other"
                color="primary"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-detail-note
                v-on:click="detailNote(item.id)"
              >
                詳細
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNoteAllUser.total"
      >
        <p class="font-weight-bold">
          {{ listNoteAllUser.total }}件中{{
            listNoteAllUser.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNoteAllUser.last_page"
      >
        <CPagination
          v-if="listNoteAllUser.total"
          :activePage.sync="page"
          :pages="listNoteAllUser.last_page"
          align="center"
        />
      </div>
    </div>
    <div>
      <b-modal
        id="modal-detail-note"
        size="lg"
        hide-header
        hide-footer
        class="modal-detail-note"
        style="word-wrap: break-word"
      >
        <div class="button-close">
          <b-button
            class="border-0"
            block
            @click="$bvModal.hide('modal-detail-note')"
            style="background: none"
          >
            <span aria-hidden="true" class="float-right" style="font-size: 25px"
              ><font style="vertical-align: inherit"
                ><font style="vertical-align: inherit">×</font></font
              ></span
            >
          </b-button>
        </div>
        <div class="body-modal" style="padding: 0px 30px">
          <div class="header-item d-flex justify-content-between">
            <div
              class="title-note font-weight-bold"
              style="
                word-break: break-all;
                padding-right: 3%;
                font-size: 20px;
                color: #4a4a4a;
                font-weight: 600;
              "
            >
              {{ noteById.title_note }}
            </div>
            <div
              class="header-item-time text-nowrap my-auto"
              v-if="noteById.created_at"
              style="font-size: 16px"
            >
              {{
                new Date(noteById.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
          </div>
          <div class="content-item" style="font-size: 14px">
            <div
              style="white-space: break-spaces"
              v-html="noteById.content_note"
            ></div>
          </div>
          <!-- <div class="content-item" style="font-size: 19px; font-weight: 600">
            PDF： {{ noteById.page_pdf }}枚目
          </div>
          <div class="content-item" style="font-size: 19px; font-weight: 600">
            動画： {{ noteById.seconds_watch_video }}秒
          </div> -->
          <div class="list-tags d-flex flex-wrap mt-3" style="padding: 0px">
            <!-- <div
              v-for="(element, indexs) in noteById.tags"
              :key="indexs"
              class="list-tags-item"
            >
              {{ element.tag_name }}
            </div> -->
            <div
              class="position-relative d-flex align-items-center pr-3 py-1"
              v-for="(element, indexs) in noteById.tags"
              :key="indexs"
            >
              <a class="customA"
                ><span class="customSpan">{{ element.tag_name }}</span></a
              ><img
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px"
              />
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListNote",
  data() {
    return {
      fields: tableFields.ALL_NOTE,
      dataModal: "",
      key_word: "",
      limit: Constants.LIMIT,
      page: 1,
      isLoading: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  created() {
    const request = {
      page: this.page,
      data: {
        key_word: this.key_word,
        limit: this.limit,
        shop_id: this.shop_id,
      },
    };
    this.getListNoteAllUser(request);
  },
  computed: {
    ...mapGetters([
      "listNoteAllUser",
      "noteById",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      const request = {
        page: this.page,
        data: {
          key_word: this.key_word,
          limit: this.limit,
          shop_id: this.shop_id,
        },
      };
      this.getListNoteAllUser(request);
      window.scrollTo(0, 0);
    },
    listNoteAllUser() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions({
      getListNoteAllUser: "getListNoteAllUser",
      getNoteByIdAdmin: "getNoteByIdAdmin",
    }),
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
    search() {
      this.isLoading = true;
      const request = {
        page: this.page,
        data: {
          key_word: this.key_word,
          limit: this.limit,
          shop_id: this.shop_id,
        },
      };
      this.getListNoteAllUser(request);
      this.page = 1;
    },
    reset() {
      this.key_word = "";
      const request = {
        page: this.page,
        data: {
          key_word: this.key_word,
          limit: this.limit,
          shop_id: this.shop_id,
        },
      };
      this.getListNoteAllUser(request);
      this.page = 1;
    },
    detailNote(id) {
      this.$store.commit("set", ["noteById", {}]);
      this.getNoteByIdAdmin(id);
    },
  },
};
</script>
